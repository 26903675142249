* {
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
}

body {
  height: 100%;
  background-color: #c0c0c0;
}

#root {
  height: 100%;
}

a {
  text-decoration: none;
}

.app {
  height: 100%;
}
